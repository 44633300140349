
@Reflect.metadata("name", "FirmwareVersion")
export default class FirmwareVersion {
    @Reflect.metadata("type", Number)
    id!: number;
    @Reflect.metadata("type", Number)
    version!: number;
    @Reflect.metadata("type", String)
    link!: string;
    @Reflect.metadata("type", Boolean)
    deleted: boolean = false;
}