import Location from "./Location";
import Region from "./Region";
import { BatteryStatus } from "../enums/BatteryStatus";
import { EntityStatus } from "../enums/EntityStatus";
import 'reflect-metadata'
import Reservation from "./Reservation";
import User from "./User";
import Hub from "./Hub";
import { ScooterStatus } from "../enums/ScooterStatus";
import FirmwareVersion from "./FirmwareVersion";

@Reflect.metadata("name", "Scooter")
export default class Scooter {
    @Reflect.metadata("type", String)
    name!: string;
    @Reflect.metadata("type", Number)
    id!: number;
    @Reflect.metadata("type", String)
    model!: string;
    @Reflect.metadata("type", Number)
    serialNumber!: number;
    @Reflect.metadata("type", Object)
    currentLocation!: Location;
    @Reflect.metadata("type", Number)
    batteryStatus!: BatteryStatus;
    @Reflect.metadata("type", Number)
    batteryPercentage!: number;
    @Reflect.metadata("type", Number)
    secondBatteryPercentage!: number;
    @Reflect.metadata("type", Number)
    battery12Volt!: number;
    @Reflect.metadata("type", Number)
    dailyMileage!: number;
    @Reflect.metadata("type", Number)
    totalMileage!: number;
    @Reflect.metadata("type", String)
    entityStatus!: string;
    @Reflect.metadata("type", String)
    status!: ScooterStatus;
    @Reflect.metadata("type", Number)
    regionId!: number;
    @Reflect.metadata("type", Object)
    region!: Region;
    @Reflect.metadata("type", Object)
    scooterReservations!: ScooterReservation[];
    @Reflect.metadata("type", Number)
    hubId!: number;
    @Reflect.metadata("type", Object)
    hub!: Hub;
    @Reflect.metadata("type", Number)
    firmwareVersion!: FirmwareVersion;
}

export class ScooterReservation {
    id!: number;
    reservation!: Reservation;
    driver!: User;
}