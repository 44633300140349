import React, { FormEvent, useEffect, useState, } from 'react'
import NetService from '../../services/NetService'
import { getLocalizedStrings } from '../../App'
import { useSVGOverlay } from 'react-leaflet/lib/SVGOverlay';
import FirmwareVersion from '../../common/models/FirmwareVersion';
import CrudTable from '../../components/CrudTable';
import { Alert, Button, Card, Grid, Modal, Snackbar, TextField } from '@mui/material';

export default function FirmwareVersions() {

    const [firmwareVersions, setFirmwareVersions] = useState<FirmwareVersion[]>([]);
    const [createData, setCreateData] = useState<FirmwareVersion>(new FirmwareVersion());
    const [createModal, setCreateModal] = useState<boolean>(false);
    const [successSnackbar, setSuccessSnackbar] = useState<boolean>(false);
    const [errorSnackbar, setErrorSnackbar] = useState<boolean>(false);
    const strings = getLocalizedStrings();



    useEffect(() => {

        NetService.AGet("/firmware")
            .then(o => setFirmwareVersions(o.data.data.FirmwareVersions));

    }, []);

    function handleChange(e: { target: { name: any; value: any; }; }) {

        const { name, value } = e.target;
        const [parent, child] = name.split(".");

        (child !== undefined) ? (
            setCreateData({
                ...createData,
                [parent]: {
                    ...createData[parent as keyof typeof createData] as {},
                    [child]: value
                }
            })
        ) : (
            setCreateData({ ...createData, [parent]: value })
        )
    }

    function handleCreateFirmwareVersion(e: FormEvent) {
        e.preventDefault();

        NetService.APost("/firmware", createData)
            .then(o => {

                if (o.status === 201) {
                    setCreateModal(false);
                    setCreateData(new FirmwareVersion());
    
                    setSuccessSnackbar(true);
                } else {
                    setErrorSnackbar(true);
                }
            });
    }

    return (

        <div>
            <CrudTable cancelRequest enableFiltering endpoint="/firmware" dataType={FirmwareVersion} dataInstance={new FirmwareVersion()} renderTopToolbarCustomActions={({ }) => {
                return (
                    <div>
                        <Button variant='contained' color='success' onClick={() => setCreateModal(true)}>{strings.create}</Button>
                    </div>
                )
            }} data={firmwareVersions} columns={[]} />


            <Modal style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} open={createModal} onClose={() => setCreateModal(false)}>
                <Card style={{ borderRadius: "10px", padding: "40px", width: "40%" }}>

                    <a style={{ fontSize: 30 }} >{strings.create}</a>

                    <form onSubmit={handleCreateFirmwareVersion} >
                        <Grid style={{ display: "flex", alignItems: "center", padding: "10px 0px" }} container spacing={2} >

                            <Grid item xs={3} >
                                <TextField type='number' required label={strings.firmwareVersion.version} name='version' fullWidth value={createData.version} onChange={handleChange} />
                            </Grid>

                            <Grid item xs={9} >
                                <TextField type='string' required label={strings.firmwareVersion.link} name='link' fullWidth value={createData.link} onChange={handleChange} />
                            </Grid>

                        </Grid>

                        <Button style={{ margin: "10px 0px 0px 0px" }} size='large' variant='contained' color='success' type='submit' >{strings.save}</Button>

                    </form>
                </Card>
            </Modal>

            <Snackbar open={successSnackbar} autoHideDuration={6000} onClose={() => setSuccessSnackbar(false)} >
                <Alert onClose={() => setSuccessSnackbar(false)} severity='success' sx={{ width: '100%' }}>
                    Firmwareversion wurde erfolgreich erstellt!
                </Alert>
            </Snackbar>

            <Snackbar open={errorSnackbar} autoHideDuration={6000} onClose={() => setErrorSnackbar(false)} >
                <Alert onClose={() => setErrorSnackbar(false)} severity='error' sx={{ width: '100%' }}>
                    Firmwareversion konnte nicht erstellt werden!
                </Alert>
            </Snackbar>
        </div>
    );
};
